import http from '@/utils/axios'

const data = {
  // 获取引擎列表
  getEngineList: (data) => http.post('/h5-app/page/engine/query/page', data),
  addEngine: (data) => http.post('/h5-app/page/engine/create', data),
  updateEngine: (data) => http.post('/h5-app/page/engine/update', data),
}

export default data
