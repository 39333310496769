<!-- 新增版本 -->
<template>
  <div class="h100" v-loading="loading">
    <div class="common-header">{{ operType === 'add' ? '新增版本' : '编辑版本' }}</div>
    <el-form label-width="85px" :model="formData" :rules="rules" ref="form">
      <el-form-item label="代码分支：" prop="version">
        <el-input v-model="formData.version" placeholder="请输入代码分支" clearable :disabled="formData.defaultBranch"></el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="formData.remark" type="textarea" placeholder="请输入备注" clearable></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Api from '@/api/devops/engineVersion'
export default {
  props: {
    detail: { default: () => {}, type: Object },
    operType: { type: String, default: 'add' },
  },
  data() {
    return {
      loading: false,
      formData: {},
      rules: {
        version: [{ required: true, message: '请输入代码分支', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },
    }
  },
  watch: {
    detail(val) {
      this.formData = _.isEmpty(val) ? {} : _.cloneDeep(val)
      this.$refs['form'].resetFields()
    },
  },
  methods: {
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            let param = {
              pageEngineId: this.$route.query.engineId,
              ...this.formData,
            }
            await Api.addEngineVersion(param)

            this.$msg.success('保存成功！')
            this.$emit('save')
            this.resetForm()
          } finally {
            this.loading = false
          }
        }
      })
    },
    // 重置
    resetForm() {
      this.$refs['form'].resetFields()
      this.formData = _.cloneDeep(this.detail)
    },
  },
}
</script>
<style lang="scss" scoped></style>
