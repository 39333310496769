import http from '@/utils/axios'

const data = {
  pageEngineVersion: (data) => http.post('/h5-app/page/engine/version/query/page', data),
  addEngineVersion: (data) => http.post('/h5-app/page/engine/version/create', data),
  updateEngineVersion: (data) => http.post('/h5-app/page/engine/version/update', data),
  deleteEngineVersion: (data) => http.get('/h5-app/page/engine/version/delete', data),
}

export default data
