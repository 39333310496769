<!-- 版本管理 -->
<template>
  <el-dialog :visible="show" title="版本列表" @close="close" append-to-body fullscreen>
    <div class="flex" style="height: calc(100vh - 90px)">
      <common-layout class="flex-1 flex-column h100">
        <template #header>
          <el-form inline @submit.native.prevent>
            <el-form-item>
              <el-input placeholder="请输入版本号" v-model="searchForm.version" clearable> </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="query(1)">查询</el-button>
              <el-button type="primary" @click="handleEvent('add', {})">新增</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #default>
          <!--表格-->
          <div v-loading="tableLoading" class="w100 h100">
            <el-table ref="singleTable" highlight-current-row :data="tableData" border height="100%">
              <el-table-column v-for="item in tableHeader" :key="item.code" :prop="item.code" :label="item.name" align="center" :min-width="item.width" />
              <el-table-column fixed="right" label="操作" width="120" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleEvent('config', scope.row)">配置</el-button>
                  <el-button type="text" @click="handleEvent('del', scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
        <template #footer>
          <!--分页-->
          <div class="mt10">
            <el-pagination :page-size="page.pageSize" :current-page="page.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
          </div>
        </template>
      </common-layout>
      <div class="ml10 border-left-line pl10 left">
        <w-form-box :operType="operType" :detail="curRow" @save="save" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Api from '@/api/devops/engineVersion'

import WFormBox from './formBox.vue'
export default {
  props: {
    show: { type: Boolean, default: false },
    detail: { default: () => {}, type: Object },
  },
  components: { WFormBox },
  data() {
    return {
      tableLoading: false,
      searchForm: {},
      tableData: [],
      page: {
        pageSize: 20,
        pageNum: 1,
      },
      tableHeader: [
        { code: 'version', name: '迭代版本', width: 150 },
        { code: 'remark', name: '备注', width: 120 },
        { code: 'createdBy', name: '创建账号', width: 100 },
        { code: 'createdTime', name: '创建时间', width: 120 },
        { code: 'updatedBy', name: '修改账号', width: 100 },
        { code: 'updatedTime', name: '修改时间', width: 120 },
      ],
      total: 0,
      operType: 'add',
      curRow: {},
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.searchForm = {}
        this.query(1)
        this.operType = 'add'
        this.curRow = {}
      }
    },
  },
  async created() {
    await this.query(1)
  },
  methods: {
    async query(pageNum) {
      try {
        pageNum && this.$set(this.page, 'pageNum', pageNum)
        this.tableLoading = true
        let param = {
          pageEngineId: this.detail.id,
          ...this.searchForm,
          ...this.page,
        }
        const { data } = await Api.pageEngineVersion(param)
        this.tableData = data?.dataList || []
        this.total = data.totalCount
      } finally {
        this.tableLoading = false
      }
    },
    handleEvent(type, row) {
      if (type === 'add') {
        this.operType = type
        this.curRow = { ...row }
      } else if (type === 'del') {
        this.$confirm(`确定删除此版本吗！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          try {
            this.tableLoading = true
            await Api.deleteEngineVersion({ id: row.id })
            this.$msg.success('删除成功！')
            await this.query(1)
          } finally {
            this.tableLoading = false
          }
        })
      } else if (type === 'config') {
        this.$router.push({
          path: '/devops/pageEngineConfig/pageModule',
          query: { engineVersionId: row.id, engineVersion: row.version, engineId: this.detail.id, engineCode: this.detail.code, engineName: this.detail.name },
        })
      }
    },

    save() {
      this.query(1)
      this.operType = 'add'
      this.curRow = {}
    },

    handleSizeChange(val) {
      this.$set(this.page, 'pageSize', val)
      this.$set(this.page, 'pageNum', 1)
      this.query(1)
    },
    handleCurrentChange(val) {
      this.$set(this.page, 'pageNum', val)
      this.query(val)
    },
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.left {
  width: 300px;
  overflow: auto;
}
</style>
