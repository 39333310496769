<template>
  <el-dialog :visible="show" @close="close" :close-on-click-modal="false" width="600px">
    <template #title>{{ titleData[operType] }}</template>
    <el-form label-width="120px" ref="form" :model="formData" :rules="rules" class="pr20">
      <el-form-item label="引擎名称:" prop="name">
        <el-input v-model="formData.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="组长工号:" prop="developLeaderAccount">
        <el-autocomplete v-model="formData.developLeaderAccount" :fetch-suggestions="querySearchAsync" placeholder="请输入工号" @select="handleGroupSelect" class="w100">
          <template slot-scope="{ item }"> {{ item.account }} - {{ item.name }} </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="组长昵称:" prop="developLeaderName">
        <el-input v-model="formData.developLeaderName" placeholder="请输入" disabled></el-input>
      </el-form-item>
      <el-form-item label="经理工号:" prop="productManagerAccount">
        <el-autocomplete v-model="formData.productManagerAccount" :fetch-suggestions="querySearchAsync" placeholder="请输入工号" @select="handleManagerSelect" class="w100">
          <template slot-scope="{ item }"> {{ item.account }} - {{ item.name }} </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="经理昵称:" prop="productManagerName">
        <el-input v-model="formData.productManagerName" placeholder="请输入" disabled></el-input>
      </el-form-item>
      <el-form-item label="应用描述:">
        <el-input v-model="formData.remark" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button type="primary" plain @click="close">取消</el-button>
      <el-button type="primary" @click="save" :loading="loading">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Api from '@/api/devops/engineList'
export default {
  props: {
    show: { type: Boolean, default: false },
    detail: { default: () => {}, type: Object },
    operType: { type: String, default: 'add' },
  },
  data() {
    return {
      formData: {},
      titleData: {
        add: '新增引擎',
        edit: '修改引擎',
      },
      rules: {
        name: [{ required: true, message: '请输入引擎名称', trigger: ['blur', 'change'] }],
        developLeaderAccount: [{ required: true, message: '请输入开发组长工号', trigger: ['blur', 'change'] }],
        developLeaderName: [{ required: true, message: '请输入开发组长昵称', trigger: ['blur', 'change'] }],
        productManagerAccount: [{ required: true, message: '请输入经理工号', trigger: ['blur', 'change'] }],
        productManagerName: [{ required: true, message: '请输入经理昵称', trigger: ['blur', 'change'] }],
      },
      loading: false,
      timeout: null,
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.formData = _.isEmpty(this.detail) ? {} : _.cloneDeep(this.detail)
        this.$refs['form']?.resetFields()
      }
    },
  },

  methods: {
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            if (this.operType === 'add') {
              await Api.addEngine(this.formData)
            } else {
              await Api.updateEngine(this.formData)
            }
            this.$msg.success('保存成功！')
            this.$emit('save')
          } finally {
            this.loading = false
          }
        }
      })
    },

    // 通过工号获取用户名称
    async getUserName(val) {
      if (!val) return
      let data = await this.$store.dispatch('GET_USER_DETAIL_BY_ACCOUNT', { account: val, useStatus: true })
      return data?.records || []
    },
    async querySearchAsync(queryString, cb) {
      if (!queryString) {
        cb([])
        return
      }
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        let data = (await this.getUserName(queryString)) || []
        data.forEach((item) => (item.value = item.account))
        cb(data)
      }, 500)
    },
    handleGroupSelect(data) {
      this.$set(this.formData, 'developLeaderName', data.name)
    },
    handleManagerSelect(data) {
      this.$set(this.formData, 'productManagerName', data.name)
    },
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>
<style lang="scss">
.none {
  display: none !important;
}
</style>
