<!-- 页面引擎 -->
<template>
  <div class="h100 page-wrapper">
    <common-layout class="flex-column h100">
      <template #header>
        <el-form inline @submit.native.prevent>
          <el-form-item>
            <el-input placeholder="请输入引擎名称" v-model="searchForm.name" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query(1)">查询</el-button>
            <el-button type="primary" @click="handleEvent('add')">新增</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table ref="table" :data="tableData" border highlight-current-row height="100%" v-loading="loading">
          <el-table-column v-for="item in tableHeader" :key="item.code" :prop="item.code" :label="item.name" align="center" :min-width="item.width" />
          <el-table-column label="操作" align="center" width="140" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEvent('edit', scope.row)">修改</el-button>
              <el-button type="text" @click="handleEvent('version', scope.row)">版本</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination :page-size="page.pageSize" :current-page="page.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </template>
    </common-layout>

    <w-form-box :show.sync="showAdd" :operType="operType" :detail="curRow" @save="save" />
    <w-version :show.sync="showVersion" :detail="curRow"/>
  </div>
</template>

<script>
import WFormBox from './formBox.vue'
import WVersion from './versionMgt/index'

import Api from '@/api/devops/engineList'

export default {
  components: { WFormBox, WVersion },
  data() {
    return {
      loading: false,
      searchForm: {},
      tableData: [],
      tableHeader: [
        { code: 'name', name: '引擎名称', width: 150 },
        { code: 'developLeaderAccount', name: '研发组长账号', width: 150 },
        { code: 'developLeaderName', name: '研发组长昵称', width: 120 },
        { code: 'productManagerAccount', name: '产品经理账号', width: 100 },
        { code: 'productManagerName', name: '产品经理昵称', width: 120 },
        { code: 'remark', name: '备注', width: 120 },
        { code: 'createdBy', name: '创建账号', width: 100 },
        { code: 'createdTime', name: '创建时间', width: 140 },
        { code: 'updatedBy', name: '修改账号', width: 100 },
        { code: 'updatedTime', name: '修改时间', width: 140 },
      ],
      operType: 'add',
      showAdd: false,
      curRow: {},
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      showVersion: false
    }
  },
  created() {
    this.query()
  },
  methods: {
    async query(pageNum) {
      try {
        this.loading = true
        pageNum && this.$set(this.page, 'pageNum', pageNum)
        const param = {
          ...this.searchForm,
          ...this.page,
        }
        const { data } = await Api.getEngineList(param)
        this.tableData = data.dataList || []
        this.total = data.totalCount
      } finally {
        this.loading = false
      }
    },

    handleEvent(type, row) {
      switch (type) {
        case 'add':
          this.operType = type
          this.curRow = {}
          this.showAdd = true
          break
        case 'edit':
          this.operType = type
          this.curRow = { ...row }
          this.showAdd = true
          break
        case 'version':
          this.showVersion = true
          this.curRow = { ...row }
          break
      }
    },
    save() {
      this.curRow = {}
      this.operType = 'add'
      this.showAdd = false
      this.query(1)
    },
    handleSizeChange(val) {
      this.$set(this.page, 'pageSize', val)
      this.$set(this.page, 'pageNum', 1)
      this.query(1)
    },
    handleCurrentChange(val) {
      this.$set(this.page, 'pageNum', val)
      this.query(val)
    },
  },
}
</script>
<style lang="scss" scoped></style>
